var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    [
      _c(
        "b-row",
        { attrs: { "align-h": "center", "align-v": "center" } },
        [
          _c("b-col", { attrs: { cols: "4" } }, [
            _c("h3", [_vm._v("PMGateway is down for maintenance")]),
            _vm.offlineEnd
              ? _c("p", [
                  _vm._v(
                    " Maintenance is scheduled to be complete by " +
                      _vm._s(_vm.$d(_vm.offlineEnd, "long")) +
                      " "
                  ),
                ])
              : _c("p", [_vm._v(" Please check back in a bit ")]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }